import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import TableCountCell from '../../../components/table-count-cell/table-count-cell.componet';

export const SALES_ORDER_INFO = {
  formTitle: "Sales Order",
  formTitleForNew: "New Sales Order",
  path: "/salesorders",
  listPage: "/sales-orders"
}

export const SALES_ORDER_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 3,
      fieldName: "salesOrder",
      fieldItems: [
        {
          label: "SO Number",
          type: "text",
          name: "salesOrderNumber",
          initialFocus: false,
          disabled: true,
          defaultValue: `SO-`
        },
        {
          label: "Customer",
          type: "select",
          name: "Customer",
          required: false,
          initialFocus: false,
          muliselect: false,
          url: "/customers",
          // validationProps: {
          //   required: "Customer is required"
          // }
        },
        // {
        //   label: "Assign To",
        //   type: "select",
        //   name: "User",
        //   required: false,
        //   initialFocus: false,
        //   muliselect: false,
        //   url: "/users"
        // },
        {
          label: "Customer's PO Number",
          type: "text",
          name: "customerPurchaseOrderNumber",
          initialFocus: false
        },
        {
          label: "SO Date",
          type: "date",
          name: "salesOrderDate",
          initialFocus: false,
          validationProps: {
            required: "SO date is required"
          }
        },
        {
          label: "Location",
          type: "select",
          name: "Location",
          initialFocus: false,
          muliselect: false,
          url: "/locations",
          validationProps: {
            required: "Location is required"
          }
        },
        {
          label: "Delivery Date",
          type: "date",
          name: "deliveryDate",
          initialFocus: false,
        },
      ]
    },
    shippingDetails: {
      sectionType: "Shipping details",
      sectionTypeId: "shippingDetails",
      multiForm: false,
      mutliFormTitle: "Shipping",
      expandable: true,
      isExpand: false,
      addButton: true,
      breakPoint: 1,
      fieldName: "shipping",
      fieldItems: [
        // {
        //   label: "Shipping Method",
        //   type: "text",
        //   name: "shippingMethod",
        //   initialFocus: false,
        // },
        {
          label: "Shipping Cost (ETB)",
          type: "text",
          name: "shippingCost",
          initialFocus: false,
        },
      ]
    },
    statusDetails: {
      sectionType: "Status details",
      sectionTypeId: "statusDetails",
      multiForm: false,
      expandable: true,
      isExpand: false,
      addButton: true,
      breakPoint: 1,
      fieldName: "status",
      fieldItems: [
        {
          label: "Current Status",
          type: "select",
          name: "SalesOrderStatus",
          initialFocus: false,
          muliselect: false,
          defaultValue: { label: 'unfulfilled', value: 1 },
          url: "/salesorderstatus"
        }
      ]
    },
    itemTaxDetails: {
      sectionType: "Item tax details",
      sectionTypeId: "itemTaxDetails",
      multiForm: false,
      expandable: true,
      isExpand: false,
      addButton: true,
      breakPoint: 1,
      fieldName: "itemTax",
      fieldItems: [
        {
          label: "Item Tax",
          type: "select",
          name: "ItemTax",
          initialFocus: false,
          muliselect: false,
          hasDefaultValue: true, 
          defaultValueUrl: "/itemtaxesdefault",
          url: "/itemtaxes",
          validationProps: {
            required: "Item tax is required"
          }
        }
      ]
    },
    tableForm: {
      sectionType: "Items",
      sectionTypeId: "tableForm",
      fieldName: "items",
      tableColumns: [
        {
          Header: "No.",
          id: "count",
          width: 50,
          Cell: ({ row }) => {
            const count = Number(row.id) + 1
            const hasSalesReturn = row.original.hasSalesReturn;
            return (
              <TableCountCell
                count={count}
              />
            )
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Item Code/Name"}
                required={true}
              />
            )
          },
          accessor: "item",
          width: 200,
          Cell: ({ row }) => {
            const endpointUrl = "/items";
            const validationProps = {
              required: "Item Code is required"
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Item"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Qty"}
                required={true}
              />
            )
          },
          accessor: "qty",
          width: 80,
          Cell: ({ row }) => {
            const validationProps = {
              required: "Qty is required"
            }

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"qty"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={validationProps}
              align="right"
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"UoM"}
                required={true}
              />
            )
          },
          accessor: "unitOfMeasure",
          width: 120,
          Cell: ({ row }) => {
            const endpointUrl = "/unitofmeasures";
            const validationProps = {
              required: "UoM is required"
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"UnitOfMeasure"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Unit Price (ETB)"}
                required={true}
              />
            )
          },
          accessor: "unitPrice",
          width: 140,
          Cell: ({ row, value }) => {
            const validationProps = {
              required: "Unit price is required"
            }

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"unitPrice"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={validationProps}
              align="right"
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: "Subtotal (ETB)",
          accessor: "subTotal",
          width: 170,
          Cell: ({ row }) => {
            return (
              <FormTableCalculateDisplay
                fieldName={"items"}
                rowNumber={row.id}
                calculateType="multiply"
                valuesToCalculate={["qty", "unitPrice"]}
              />
            )
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
    totalDetails: {
      sectionType: "Total",
      sectionTypeId: "totalDetails",
      multiForm: false,
      expandable: false,
      isExpand: true,
      addButton: true,
      breakPoint: 0,
      fieldName: "total",
      fieldItems: [
        {
          label: "Item Count",
          type: "text",
          name: "itemCount",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Subtotal",
          type: "text",
          name: "subTotal",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Item Tax (ETB)",
          type: "text",
          name: "itemTax",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Shipping Cost (ETB)",
          type: "text",
          name: "shippingCost",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Grand Total (ETB)",
          type: "text",
          name: "grandTotal",
          initialFocus: false,
          disabled: true
        }
      ],
    },
    paymentDetails: {
      sectionType: "Payment details",
      sectionTypeId: "paymentDetails",
      showEditOnly: true, 
      multiForm: false,
      mutliFormTitle: "",
      expandable: true,
      isExpand: true,
      addButton: true,
      breakPoint: 0,
      fieldName: "payment",
      fieldItems: [
        {
          label: "Paid Amount (ETB)",
          type: "text",
          name: "paidAmount",
          initialFocus: false,
        },
        {
          label: "Avaliable Credit (ETB)",
          type: "text",
          name: "availableCreditAmount",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Creidt Applied (ETB)",
          type: "text",
          name: "appliedCreditAmount",
          initialFocus: false,
          disabled: false
        },
        {
          label: "Total Paid Amount (ETB)",
          type: "text",
          name: "totalPaidAmount",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Balance (ETB)",
          type: "text",
          name: "balance",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Refund amount (ETB)",
          type: "text",
          name: "refundAmount",
          initialFocus: false,
          disabled: false
        },
        {
          label: "Issue Credit (ETB)",
          type: "text",
          name: "issuedCreditAmount",
          initialFocus: false,
          disabled: false
        }
      ]
    },
    salesInvoiceDetails: {
      sectionType: "Invoice details",
      sectionTypeId: "invoiceDetails",
      multiForm: false,
      mutliFormTitle: "",
      expandable: true,
      isExpand: true,
      addButton: true,
      breakPoint: 1,
      fieldName: "invoice",
      fieldItems: [
        {
          label: "Invoice Date",
          type: "date",
          name: "invoicedDate",
          initialFocus: false,
        },
        {
          label: "Invoice Number",
          type: "text",
          name: "fsNumber",
          initialFocus: false,
        },
      ]
    },
    receiptReDetails: {
      sectionType: "Receipt details",
      sectionTypeId: "receiptDetails",
      multiForm: false,
      mutliFormTitle: "",
      expandable: true,
      isExpand: false,
      addButton: true,
      position: "LAST",
      breakPoint: 1,
      fieldName: "receipt",
      fieldItems: [
        {
          label: "Receipt Date",
          type: "date",
          name: "receiptDate",
          initialFocus: false,
        },
        {
          label: "Receipt Number",
          type: "text",
          name: "receiptNumber",
          initialFocus: false,
        },
      ]
    },
  },
  watchFields: [
    "shipping.0.shippingCost",
    "itemTax.0.ItemTax",
    "payment.0.paidAmount",
    "payment.0.availableCreditAmount",
    "payment.0.appliedCreditAmount",
    "payment.0.refundAmount",
    "payment.0.issuedCreditAmount"
  ]
}
